$(function () {

    $(".sf-menu").superfish({
            delay: 200,
            speed: "fast",
            cssArrows: false
        })
        .after("<div id='mobile-menu'>").clone().appendTo("#mobile-menu");
    $("#mobile-menu").find("*").attr("style", "");
    $("#mobile-menu").children("ul").removeClass("sf-menu")
        .parent().mmenu({
            extensions: ['widescreen', 'theme-white', 'effect-menu-slide', 'pagedim-black'],
            navbar: {
                title: "Меню"

            }
        });

    $(".toggle-mnu").click(function () {
        $(this).addClass("on");

    });

    var api = $("#mobile-menu").data("mmenu");
    api.bind("closed", function () {
        $(".toggle-mnu").removeClass("on");
    });
});

/*Фиксируем меню*/

$(window).scroll(function () {
    if ($(this).scrollTop() > 102) {
        $('.menu_wrap').addClass('scrolled');
    } else {
        $('.menu_wrap').removeClass('scrolled');
    }
});



/*Слик-слайдер*/

$(function () {
    $('.slider-top').slick({
        dots: false,
        fade: false,
        infinite: true,
        speed: 500,
        cssEase: 'linear',
        autoplay: false,
        autoplaySpeed: 3000,
        appendArrows: '.arrows',
        prevArrow: '<button type="button" class="pr-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="pr-next"><i class="fa fa-angle-right"></i></button>'

    });
    $('.categories').slick({

        slidesToShow: 5,
        slidesToScroll: 1,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2000,
        appendArrows: '.collect__arrows',
        prevArrow: '<button type="button" class="pr-prev1"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="pr-next1"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                }
                    },


            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                }
                    },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                }
                    },

                ]
    });
    $('.slider-tabs1').slick({

        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        appendArrows: '.arrows-tabs1',
        prevArrow: '<button type="button" class="pr-prev1"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="pr-next1"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
                    },


            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
                    },

                ]
    });
    $('.slider-tabs2').slick({

        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        appendArrows: '.arrows-tabs2',
        prevArrow: '<button type="button" class="pr-prev2"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="pr-next2"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
                    },


            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
                    },

                ]
    });
    $('.slider-tabs3').slick({

        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        appendArrows: '.arrows-tabs3',
        prevArrow: '<button type="button" class="pr-prev3"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="pr-next3"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
                    },


            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
                    },

                ]
    });
    $('.about-slider').slick({
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        cssEase: 'linear',
        autoplay: false,
        autoplaySpeed: 3000,
        appendArrows: '.about-arrows',
        prevArrow: '<button type="button" class="prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="next"><i class="fa fa-angle-right"></i></button>'

    });
    $('.slider-thumb').slick({
        autoplay: false,
        vertical: true,
        infinite: true,
        verticalSwiping: true,
        slidesPerRow: 4,
        slidesToShow: 4,
        asNavFor: '.slider-preview',
        focusOnSelect: true,
        prevArrow: '<button type="button" class="slick-prev3"><i class="fa fa-angle-up"></i></button>',
        nextArrow: '<button type="button" class="slick-nextt3"><i class="fa fa-angle-down"></i></button>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesPerRow: 3,
                    slidesToShow: 3,
                }
                    },




            {

                breakpoint: 767,
                settings: {
                    vertical: false,
                }
                    },
            {
                breakpoint: 479,
                settings: {
                    vertical: false,
                    slidesPerRow: 3,
                    slidesToShow: 3,
                }
                    },
                ]
    });
    $('.slider-preview').slick({

        autoplay: false,
        vertical: true,
        infinite: true,
        slidesPerRow: 1,
        slidesToShow: 1,
        asNavFor: '.slider-thumb',
        arrows: false,
        draggable: false,
        responsive: [

            {
                breakpoint: 767,
                settings: {
                    vertical: false,
                    fade: true,
                }
                }, ]
    });



});


/*Кастомный скрол*/
document.querySelectorAll('.category-page__descr').forEach(el => {
    new SimpleBar(el)
});

/*Отправка форм*/
let selector = document.querySelectorAll('input[type="tel"]');
let im = new Inputmask('+7 (999) 999-99-99');
im.mask(selector);

let selector2 = document.querySelector('input[type="tel"]');

selector2.addEventListener('input', function () {
    console.log(selector2.value)


    const re = /^\d*(\.\d+)?$/

    console.log(selector2.value.match(/[0-9]/g).length)


    console.log(selector2.value.replace(/[0-9]/g, "0"));

});


/*const fileInput = document.querySelector('input[type="file"]');

fileInput.addEventListener('change', (e) => {
    let files = e.currentTarget.files;
    console.log(files);

    if (files.length) {
        fileInput.closest('label').querySelector('span').textContent = files[0].name;
    } else {
        fileInput.closest('label').querySelector('span').textContent = 'Прикрепить файл';
    }

});*/

let validateForms = function (selector, rules, successModal, yaGoal) {
    new window.JustValidate(selector, {
        rules: rules,
        submitHandler: function (form) {
            let formData = new FormData(form);

            let xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        console.log('Отправлено');
                    }
                }
            }

            xhr.open('POST', 'mail/mail.php', true);
            xhr.send(formData);
            form.reset();
            /*  window.location = "/thanks";*/
            $('.fade').delay(1700).fadeOut(300);
            $('#success').fadeIn(500).delay(1700).fadeOut(600);
            /* fileInput.closest('label').querySelector('span').textContent = 'Прикрепить файл';*/
        }
    });
}

validateForms('.form', {
    email: {
        required: true,
        email: true
    },
    tel: {
        required: true
    }
}, );
validateForms('.form2', {
    email: {
        required: true,
        email: true
    },
    tel: {
        required: true
    }
}, );
validateForms('.form3', {
    email: {
        required: true,
        email: true
    },
    tel: {
        required: true
    }
}, );
